import React from 'react'
import { COLOR_CONSTANTS } from 'theme'
import App from 'App'
import SEO from 'components/SEO'
import { H1 } from 'components/Typography'
import { Box, Flex } from 'components/Layout'
import BlockOfTextWithTableOfContents from 'components/BlockOfTextWithTableOfContents'
import Footer from 'components/Footer'

const content = [
  {
    name: 'vulnerabilityreportingpolicy',
    title: 'Introduction',
    body: `
     At Vista Social, trust is our #1 value and we take the protection of our customers’ data very seriously.
     <br>
     <br>
    The Vista Social security team acknowledges the valuable role that independent security researchers play in Internet security. As a result, we encourage responsible reporting of any vulnerabilities that may be found in our site or applications. Vista Social is committed to working with security researchers to verify and address any potential vulnerabilities that are reported to us.
    <br>
    <br>
    Please review these terms before you test and/or report a vulnerability. Vista Social pledges not to initiate legal action against researchers for penetrating or attempting to penetrate our systems as long as they adhere to this policy.`,
  },
  {
    name: 'testing',
    title: 'Testing for security vulnerabilities',
    body: `
      Whenever a Trial or Beta Edition is available, please conduct all vulnerability testing against such instances. Always use test or demo accounts when testing our online services. The testing must not violate any law, disrupt and/or compromise any data that is not your own. Additional restrictions might be applied on the bounty depending on your local laws.
      `,
  },
  {
    name: 'reporting',
    title: `Reporting a potential security vulnerability`,
    body: `
        Vista Social is committed to providing a secure network to its customers. While we employ secure product development practices in our engineering process, we recognize that not all vulnerabilities may be avoided. We are committed to providing a quick response for potential vulnerabilities. 
        <ul>
            <li>Privately share details of the suspected vulnerability with Vista Social by submitting a support ticket here <a href="https://support.vistasocial.com/hc/en-us/requests/new">https://support.vistasocial.com/hc/en-us/requests/new</a>.</li>
            <li>Provide full details of the suspected vulnerability so the Vista Social security team may validate and reproduce the issue.</li>
        </ul>
     `,
  },
  {
    name: 'permit',
    title: 'Vista Social does not permit the following types of security research:',
    body: `
      While we encourage you to discover and report to us any vulnerabilities you find in a responsible manner, the following conduct is expressly prohibited:
      <ul>
            <li>Performing actions that may negatively affect Vista Social or its users (e.g. Spam, Brute Force, Denial of Service…).</li>
            <li>Accessing, or attempting to access, data or information that does not belong to you.</li>
            <li>Destroying or corrupting, or attempting to destroy or corrupt, data or information that does not belong to you.</li>
            <li>Conducting any kind of physical or electronic attack on Vista Social personnel, property or data centers.</li>
            <li>Social engineering any Vista Social service desk, employee or contractor.</li>
            <li>Conduct vulnerability testing of participating services using anything other than test accounts (e.g. Beta or Trial Edition instances).</li>
            <li>Violating any laws or breaching any agreements in order to discover vulnerabilities.</li>
      </ul>
      `,
  },

  {
    name: 'compensation',
    title: 'Compensation:',
    body: `
    We take security very seriously at Vista Social, and have an Information Security Bug Bounty program geared towards the identification and remediation of security issues. Vista Social offers following rewards as bounty depending on the severity of the findings:
     <ul>
            <li><strong>Critical</strong>: $100 USD Amazon eGift Card. </li>
            <li><strong>High</strong>: $75 USD Amazon eGift Card. </li>
            <li><strong>Medium</strong>: $25 USD Amazon eGift Card. </li>
     </ul>     
     We do not offer rewards for low severity issues. The decision to pay a bounty is entirely at our discretion.   
     <br>
     <br>
     
      `,
  },
  {
    name: 'ineligible',
    title: 'Ineligible vulnerability types',
    body: `
    Please note that Vista Social does not consider the following to be eligible vulnerabilities under this program:
     <ul>
        <li>Vulnerabilities in the third party/open source components</li>
        <li>Distributed Denial of Service</li>
        <li>Social Engineering/phishing issues</li>
        <li>Email bomb/flooding</li>
        <li>Findings from the automated scanners which are not triaged</li>
        <li>Disclosure of server or software version numbers</li>
        <li>Password strength or policy</li>
        <li>Security issues which can only be exploited with jailbroken or rooted devices.</li>
        <li>Self exploitation attacks.</li>
        <li>Vulnerabilities which can be only exploited in outdated browsers.</li>
        <li>Subresource integrity checks</li>
        <li>Header misconfigurations or missing security headers without evidence of the ability to target a remote victim</li>
        <li>Unclaimed social media accounts, links or domains which look similar to Vista Social.</li>
        <li>DMARC/SPF issues</li>
        <li>Issues related to TLS/SSL versions</li>
     </ul>     
     
     <br>
     <br>
     
      `,
  },
  {
    name: 'commitment',
    title: 'The Vista Social security team commitment:',
    body: `
     We ask that you do not share or publicize an unresolved vulnerability with/to third parties. If you responsibly submit a vulnerability report, the Vista Social security team and associated development organizations will use reasonable efforts to:
     <ul>
            <li>Respond in a timely manner, acknowledging receipt of your vulnerability report.</li>
            <li>Provide an estimated time frame for addressing the vulnerability report.</li>
            <li>Notify you when the vulnerability has been fixed.</li>
     </ul>     
     We are happy to thank every individual researcher who submits a vulnerability report helping us improve our overall security posture at Vista Social.
     <br>
     <br>
     
      `,
  },
]

const VulnerabilityReportingPolicy = () => (
  <App>
    <SEO
      title="Responsible Disclosure Policy"
      description="Learn more about the vulnerability reporting policy for Vista Social"
      path="/vulnerability-reporting-policy/"
    />
    <Flex mt="xl" flexDirection="column" alignItems="center">
      <H1 color={COLOR_CONSTANTS.DENIM} fontSize="4xl" fontWeight="bold">
        Responsible Disclosure Policy
      </H1>
    </Flex>
    <Box m="0 auto" px="m" mt="xl" maxWidth="1080px">
      <BlockOfTextWithTableOfContents content={content} />
    </Box>
    <Footer />
  </App>
)

export default VulnerabilityReportingPolicy
